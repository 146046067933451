import { DevoModule as devo } from "../Modules/DevoModule";

window.addEventListener('load', function () {
    devo.Init();
});

document.addEventListener('widgetLoaded', function (args) {
    DEBUG && console.info(args);
    if (args.detail.model.Name === "DevoViewComponent") {
        DEBUG && console.info('**************************************************');
        DEBUG && console.info('=== Widget Reload Event ===')
        DEBUG && console.info('Element: ', args.detail.element);
        DEBUG && console.info('**************************************************');
        devo.Init();
    }
});