import { ApiService as api } from "../Services/APIService";
import { TemplateService } from "../Services/TemplateService";

const devoCardTemplate = 'DevoCard';

export class DevoModule {
    static async Init() {
        DEBUG && console.info('*--> Events Module Init');

        var elements = document.querySelectorAll('[data-component="devoCard"]');

        elements.forEach(element => {

            var topLevelFolder = element.getAttribute('data-topLevelFolder');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info(`Top Level Folder:        ${topLevelFolder}`);
            DEBUG && console.info('**************************************************');

            this.InitDevoCard(element, topLevelFolder);
        });
    }

    static async InitDevoCard(element, topLevelFolder) {
        var data = await this.GetLatestDevo();

        var spanishMode = false;
        if (topLevelFolder.toLowerCase() == 'espanol') {
            spanishMode = true;
        }

        var devoData = { "data": data, "topLevelFolder": `${topLevelFolder}`, "spanishMode": spanishMode };

        var content = await TemplateService.GetRenderedTemplate(devoCardTemplate, devoData);

        element.innerHTML = content;
    }


    static async GetLatestDevo() {
        var data = await api.getData(`Devo/GetLatestDevo`);
        DEBUG && console.info(data);
        return data;
    }

}